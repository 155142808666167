import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Why from './components/Why';
import About from './components/About';
import Break from './components/Break';
import Growth from './components/Growth';
import Community from './components/Community';
import Footer from './components/Footer';

function HomePage() {
  return (
    <div>
      <Navbar />
      <Hero  />
      <Break />
      <Why />
      <Break />
      <About  />
      <Break />
      {/* <Roadmap id="roadmap" />
      <Break />
      <Team id="team" />
      <Break />
      <Collection id="collection" />
      <Break />
      <QnA id="qna" />
      <Break />
      <Footer /> */}
      <Growth id="growth" />
      <Break />
      <Community id="community" />
      <Break />
      <Footer /> 
    </div>
  );
}

export default HomePage;
