import "./Community.css";
import grow from '../images/b1.jpg';

export default function Community() {
    return (
        <section className="community">
            <h1>
                <span>community</span>
            </h1>
            <div className="community_container">
                <div className="community_text">
                    <p>The heart of Lovecoin is its community, a vibrant ecosystem of individuals committed to the ethos of love and positivity. This community is the engine that fuels Lovecoin's growth, where every member is encouraged to contribute to the collective pool of love through acts of service, mentorship, and support.
                    </p>
                    
                    <p>
                        Joining the Lovecoin community means taking a pledge to spread love in every interaction. This pledge is not a financial commitment but a promise to be a beacon of love and positivity in a world that often needs it. It's a commitment to make every encounter count, to uplift others, and to be a part of a global movement that values love as its highest currency.

                    </p>
                </div>

                <img className="community_img" src={grow} alt=""></img>

            </div>
        </section>
    )
}