import "./Growth.css";
import grow from '../images/grow.jpg';

export default function Growth() {
    return (
        <section className="growth">
            <h1>
            Sustainable
                <span> Growth</span>
            </h1>
            <div className="growth_container">
                <div className="growth_text">
                    <p>Lovecoin emerges from the profound understanding that love is the most transformative force in the universe. It is an intangible currency that values human connection, kindness, and compassion above all else.

                    </p>
                    <p>Unlike traditional currencies that fluctuate with market whims, Lovecoin stands for a stable and enduring growth model. It represents the steady accumulation of positive social interactions and the nurturing of relationships that enrich our lives. It's about personal and societal evolution that is measured in smiles, not numbers.

                        
                    </p>
                    <p>Lovecoin is about putting love into action. It's a platform where people can propose projects that spread love and positivity. Whether it's a local initiative to support the homeless, a global campaign for environmental awareness, or a simple act of kindness, Lovecoin is the catalyst.

                    </p>
                </div>

                <img className="growth_img" src={grow} alt=""></img>

            </div>
        </section>
    )
}