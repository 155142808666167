import React from "react";
import './Why.css'

export default function Why() {
  return (
    <section className="why" id='rules'>
      <div className="container-fluid">
        <div className="content">
          <h2>RULES of <span>LOVE COIN</span></h2>
          <div className="row">
            <div className="col-md-4">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-send"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-text">LOVE IS 100% GIVEN AWAY</h5>
                      {/* <p className="card-text">Use Trust Wallet, Metamask or to connect to the app.</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-heart-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-text">LOVE IS PRICELESS</h5>
                      {/* <p className="card-text">Upload your NFTs and set a text, description and price.</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-rocket"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-text">LOVE IS UNREQUITED</h5>
                      {/* <p className="card-text">Earn ETH all your NFTs that you sell on our marketplace.</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-people-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-text">LOVE MAKES PEOPLE HUMAN</h5>
                      {/* <p className="card-text">We make it easy to Discover, Invest and manage.</p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <img className="img-fluid center-img" src={require('../images/hold-coin.png')} alt="center-img" />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-star-fill"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-text">LOVE IS THE MOST PRECIOUS VALUE OF HUMANITY</h5>
                      {/* <p className="card-text">Invest and manage all your NFTs at one place on one platform.</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-globe"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-text">LOVE TRANSCENTS ALL CULTURES AND RELIGIONS</h5>
                      {/* <p className="card-text">Discover, collect the right NFT collections to buy or sell.</p> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3">
                <div className="row g-0">
                  <div className="col-md-4">
                    <div className="card-icon">
                      <i className="bi bi-bricks"></i>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-text">LOVE IS THE MOST FUNDAMENTAL AND GREATEST CONSENSUS OF HUMAMNITY</h5>
                      {/* <p className="card-text">Earn ETH all your NFTs that you sell on our marketplace.</p> */}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}