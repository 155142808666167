import React from 'react';
import './About.css';
import loveImg from '../images/hero/book.jpg';

export default function About() {
  return (
    <section className="about" id="lesson">
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-md-6 about-image">
            <img src={loveImg} className="img-fluid" alt="Collection" />
          </div>
          <div className="col-md-6 panel-content">
            <h3 className="about-title">LESSONS  <span>OF LOVE</span>
            </h3>
            <p className="about-content">LOVE is not for trading and speculation.</p>
            <p className="about-content">LOVE has nothing to do with the money in any form</p>
            <p className="about-content">Never invest your principal just because the APY is high</p>
            <p className="about-content">LOVE should never be a tool for ripping off each other</p>
            <p className="about-content">It is not about money that makes us rich, it's simplicity, love, compassion, friendships, values, family that makes our lives rich</p>
            <p className="about-content">LOVE should never be a tool for ripping off each other</p>

            {/* <div className="row justify-content-around">
              <Card title="Sync & track" value="93%" />
              <Card title="Up value" value="2.5X" />
              <Card title="Of members" value="50K+" />
            </div> */}
          </div>

          <div className="row justify-content-center boxs">
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-person-hearts"></i>
                <h2>40%</h2>
                <p>Love at first sight</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-coin"></i>
                <h2>$61,000</h2>
                <p>People married made this more per year because of love </p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-person-circle"></i>
                <h2>5</h2>
                <p>Love languges</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box">
                <i className="bi bi-sun-fill"></i>
                <h2>2.5x</h2>
                <p>Stress level 2.5 times lower </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

// const Card = ({ title, value }) => {
//   return (
//     <div className="col-md-3 about-card">
//       <h2>{value}</h2>
//       <p>{title}</p>
//     </div>
//   );
// };


