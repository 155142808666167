import React from "react";
import "./Footer.css";

// Social link component
const SocialLink = ({ href, iconClass }) => (
  <li>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <i className={iconClass}></i>
    </a>
  </li>
);


// Array of social links
const socialLinks = [
  // { href: "https://discord.gg/lovedao", iconClass: "bi bi-discord social-link" },
  { href: "https://twitter.com/lovecoincom", iconClass: "bi bi-twitter social-link" },
  { href: "https://www.facebook.com/lovecoincom", iconClass: "bi bi-facebook social-link" },
  { href: "https://www.instagram.com/lovecoincom", iconClass: "bi bi-instagram social-link" },
  { href: "https://www.tiktok.com/@lovecoincom", iconClass: "bi bi-tiktok social-link" },
  { href: "https://www.reddit.com/user/lovecoincom", iconClass: "bi bi-reddit social-link" },
  { href: "https://www.youtube.com/@lovecoincom", iconClass: "bi bi-youtube social-link" },
  { href: "https://t.me/lovecoincom", iconClass: "bi bi-telegram social-link" },
  // { href: "https://t.me/lovecoincom", iconClass: "bi bi-wechat social-link" },
];

// Array of footer links

export default function Footer() {
  return (
    <section className="Footer">
      <div className="container">
        <div className="row">
          <div className="join-us">
            <h1>
              JOIN 
              <span> US</span>
            </h1>
            <p className="subtitle">Embrace the future with Lovecoin, spreading love and stability through innovative community-driven growth.</p>
            {/* <div className="cta">
              <a href="https://discord.gg/3Qq9VJ6Z" target="_blank" rel="noopener noreferrer">
                Join Discord
              </a>
            </div> */}
          </div>

          <ul className="social-links">
            {socialLinks.map((link, index) => (
              <SocialLink key={index} href={link.href} iconClass={link.iconClass} />
            ))}
          </ul>

          {/* <ul className="footer-links">
            {footerLinks.map((link, index) => (
              <FooterLink key={index} href={link.href} text={link.text} />
            ))}
          </ul> */}

          <p className="copy">© 2024 Lovecoin.com</p>
          {/* <p className="credit">Coded with 💜 by <a href="https://github.com/s-shemmee" target="_blank" rel="noopener noreferrer">@s-shemmee</a></p> */}
        </div>
      </div>
    </section>
  );
}
